import React, { useState, useEffect } from 'react';
import Particles from '@tsparticles/react';
import { loadFull } from 'tsparticles';

const FlyingMoneyBackground = () => {
  console.log("Rendering Particles component");

  const particlesInit = async (main) => {
    console.log("Initializing particles...");
    try {
      await loadFull(main);
      console.log("Particles successfully initialized");
    } catch (error) {
      console.error("Error initializing particles:", error);
    }
  };

  const particlesLoaded = (container) => {
    if (container) {
      console.log("Particles container loaded:", container);
    } else {
      console.error("Particles container failed to load");
    }
  };

  return (
    <Particles
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        particles: {
          number: { value: 20 }, // Simple configuration for testing
          shape: { type: 'circle' },
          move: { enable: true, speed: 5 },
        },
      }}
      style={{
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
        background: 'rgba(0, 0, 0, 0.5)', // Temporary debug background
      }}
    />
  );
};

export default FlyingMoneyBackground;
