import React, { useState, useEffect, useCallback, useRef } from 'react';
import { IoPersonCircle } from "react-icons/io5";
import { IoArrowBack } from 'react-icons/io5';
import './admin.css';

const getCookie = (name) => {
    const cookies = document.cookie.split(';').reduce((acc, cookie) => {
        const [key, value] = cookie.trim().split('=');
        acc[key] = value;
        return acc;
    }, {});
    return cookies[name] || null;
};

const AdminChat = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const [activeUsers, setActiveUsers] = useState([]);
    const [messagesByUser, setMessagesByUser] = useState({});
    const [lastChatted, setLastChatted] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [response, setResponse] = useState('');
    const [seenMessages, setSeenMessages] = useState([]);
    const [ isMobile, setIsMobile ] = useState(window.innerWidth <= 768);
    const messagesEndRef = useRef(null);

    const checkLoginStatus = () => {
        const loggedInCookie = getCookie('loggedIn') === 'true';
        setLoggedIn(loggedInCookie);
    };

    useEffect(() => {
        checkLoginStatus();
    }, []);

    const handleLogin = () => {
        fetch('https://cryptoxnft.org/chat/admin/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, password }),
            credentials: 'include',
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`Login failed with status ${res.status}`);
                }
                return res.json();
            })
            .then((data) => {
                if (data.message === 'Login successful') {
                    checkLoginStatus();
                } else {
                    setLoginError(data.error || 'Login failed');
                }
            })
            .catch((err) => setLoginError(err.message || 'Unable to login. Please try again.'));
    };

    const truncateMessage = (message, maxLength) => {
        if (message.length > maxLength) {
            return message.slice(0, maxLength) + '...';
        }
        return message;
    };

    const truncateUserId = (message, maxLength) => {
        if (message.length > maxLength) {
            return message.slice(0, maxLength) + '...';
        }
        return message;
    };

    const fetchActiveUsers = useCallback(() => {
        fetch('https://cryptoxnft.org/chat/admin/active-users')
            .then((res) => res.json())
            .then((data) => {
                const validUsers = (data.activeUsers || []).filter(
                    (user) =>
                        user.userId &&
                        user.userId !== 'Unknown' &&
                        !user.isAdmin &&
                        user.currentPage !== '/admin'
                );

                setActiveUsers((prev) =>
                    JSON.stringify(prev) !== JSON.stringify(validUsers) ? validUsers : prev
                );
            })
            .catch((err) => console.error('Error fetching active users:', err));
    }, []);

    useEffect(() => {
        if (!loggedIn) return;

        fetchActiveUsers();
        const intervalId = setInterval(fetchActiveUsers, 1000);

        return () => clearInterval(intervalId);
    }, [loggedIn, fetchActiveUsers]);

    const fetchLastChatted = useCallback(() => {
        fetch('https://cryptoxnft.org/chat/admin/last-chatted-users')
            .then((res) => res.json())
            .then((data) => {
                const users = data.users || [];
                const sortedUsers = users.sort((a, b) => {
                    const lastMessageA = a.messages[a.messages.length - 1];
                    const lastMessageB = b.messages[b.messages.length - 1];
                    const timestampA = new Date(lastMessageA.timestamp).getTime();
                    const timestampB = new Date(lastMessageB.timestamp).getTime();
                    return timestampB - timestampA;
                });

                setMessagesByUser((prev) => {
                    const updatedMessages = { ...prev };
                    sortedUsers.forEach(({ userId, messages }) => {
                        updatedMessages[userId] = messages;
                    });
                    return updatedMessages;
                });

                setLastChatted(
                    sortedUsers.map((user) => ({
                        userId: user.userId, // Keep for internal use
                        telegramUsername: user.telegramUsername, // Use this for display
                        seen: user.messages?.[user.messages.length - 1]?.seen || 0,
                    }))
                );
            })
            .catch((err) => console.error('Error fetching last chatted users:', err));
    }, []);

    useEffect(() => {
        if (!loggedIn) return;

        fetchLastChatted();
        const intervalId = setInterval(fetchLastChatted, 5000);

        return () => clearInterval(intervalId);
    }, [loggedIn, fetchLastChatted]);

    const updateLastChatted = (userId) => {
        setLastChatted((prev) => {
            const updated = prev.filter((entry) => entry.userId !== userId);
            return [{ userId, seen: 0 }, ...updated];
        });
    };

    const sendResponse = () => {
        if (!response.trim() || !selectedUser) return;
    
        const adminMessage = {
            text: response,
            sender: 'admin',
            timestamp: Date.now(),
            userId: selectedUser,
        };
    
        fetch('https://cryptoxnft.org/chat/admin/send-message', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(adminMessage),
        })
            .then(() => {
                setMessagesByUser((prev) => ({
                    ...prev,
                    [selectedUser]: [...(prev[selectedUser] || []), adminMessage],
                }));
                setResponse('');
            })
            .catch((err) => console.error('Failed to send admin message:', err));

        updateLastChatted()
    };
    

    const handleUserClick = (userId) => {
        setSelectedUser(userId);
        markMessagesAsSeen(userId);
    };

    const markMessagesAsSeen = (userId) => {
        fetch('https://cryptoxnft.org/chat/admin/mark-seen', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userId }),
        })
            .then(() => {
                setSeenMessages((prev) => ({
                    ...prev,
                    [userId]: true,
                }));
            })
            .catch((err) => console.error('Error marking messages as seen:', err));
    };

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messagesByUser[selectedUser]]);  
    
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (!loggedIn) {
        return (
            <div className="login-screen">
                <div className="login-container">
                    <h1>Admin Login</h1>
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button onClick={handleLogin}>Login</button>
                    {loginError && <p className="error">{loginError}</p>}
                </div>
            </div>
        );
    }

    if (isMobile) {
        return (
            <div className='admin-dashboard-mobile'>
                <h1 className='admin-dashboard-header-mobile'>Admin Dashboard</h1>
                <div className='dashboard-container-mobile'>
                    {/* ACTIVE USERS */}
                    <div className="user-list-mobile">
                        <div className="user-list-header-mobile">
                            <h2>Active Users</h2>
                        </div>
                        <div className="user-list-users-mobile">
                            {activeUsers.length > 0 ? (
                                activeUsers.map((user) => (
                                    <div
                                        key={`active-${user.telegramUsername}`}
                                        className={`user-item ${selectedUser === user.userId ? 'selected' : ''}`}
                                        onClick={() => handleUserClick(user.userId)}
                                    >
                                        <div className="active-user-container-mobile">
                                            <IoPersonCircle size={25} />
                                            <p><strong>{user.currentPage.split('/').pop()}</strong></p>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="user-list-noactive">
                                    <p>No active users.</p>
                                </div>
                            )}
                        </div>
                    </div>
    
                    {/* CHAT SECTION */}
                    <div className="chat-section-mobile">
                        {selectedUser ? (
                            <div className="chat-messages-container-mobile">
                                {/* Back Button */}
                                    <div className='selected-user-id-mobile'>
                                        <h2>{selectedUser}</h2>
                                    </div>
                                <div className="chat-window-selected-user-mobile">
                                    <div className="chat-back-button" onClick={() => setSelectedUser(null)}>
                                        <IoArrowBack size={20} /> BACK
                                    </div>
                                    {/* Messages */}
                                    <div className="messages-container-mobile" ref={messagesEndRef}>
                                        {(messagesByUser[selectedUser] || []).map((message, index) => (
                                            <div
                                                key={`msg-${index}`}
                                                className={`message-container-mobile ${message.sender}`}
                                            >
                                                <div className={`message-mobile ${message.sender}`}>
                                                    <p>{message.text}</p>
                                                </div>
                                                <div className={`timestamp-mobile ${message.sender}`}>
                                                    <small>{new Date(message.timestamp).toLocaleString()}</small>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {/* Response Section */}
                                    <div className="response-section-mobile">
                                        <input
                                            value={response}
                                            onChange={(e) => setResponse(e.target.value)}
                                            placeholder="Type your response..."
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') sendResponse();
                                            }}
                                        />
                                        <button onClick={sendResponse}>Send</button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="last-chatted-users-mobile">
                                <div className="last-chatted-header-mobile">
                                    <h2>Chat</h2>
                                </div>
                                {lastChatted.length > 0 ? (
                                    lastChatted.map(({ userId, telegramUsername }) => {
                                        const userMessages = messagesByUser[userId] || [];
                                        const lastMessage = userMessages[userMessages.length - 1];
                                        const seen = lastMessage?.seen || 0;
    
                                        return (
                                            <div
                                                key={`last-${userId}`}
                                                className={`user-item-mobile ${seen === 0 ? 'unseen-mobile' : ''}`}
                                                onClick={() => handleUserClick(userId)}
                                            >
                                                 <p><strong>{telegramUsername || 'No Username'}</strong></p> 
                                                {lastMessage && (
                                                <>
                                                    <p><strong>{lastMessage.sender}:</strong> {truncateMessage(lastMessage.text, 20)}</p>
                                                    <p>
                                                        <strong>Timestamp:</strong>{' '}
                                                        {new Date(lastMessage.timestamp).toLocaleString(undefined, {
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                            second: '2-digit',
                                                        })}
                                                    </p>
                                                </>
                                            )}
                                            </div>
                                        );
                                    })
                                ) : (
                                    <p>No last chatted users.</p>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="admin-dashboard">
            <h1>Admin Dashboard</h1>
            <div className="dashboard-container">
                <div className="user-list">
                    <div className="user-list-header">
                        <h2>Active Users</h2>
                    </div>
                    {activeUsers.length > 0 ? (
                        activeUsers.map((user) => (
                            <div
                                key={`active-${user.userId}`}
                                className={`user-item ${selectedUser === user.userId ? 'selected' : ''}`}
                                onClick={() => handleUserClick(user.userId)}
                            >
                                <p><strong>User ID:</strong> {user.userId}</p>
                                <p><strong>Current Page:</strong> {user.currentPage}</p>
                            </div>
                        ))
                    ) : (
                        <div className="user-list-noactive">
                            <p>No active users.</p>
                        </div>
                    )}
                </div>

                {/* Chat Section */}
                <div className="chat-section">
                    <div className="chat-messages-header">
                        <h2>Messages</h2>
                    </div>
                    <div className="chat-user-container">
                        {/* Last Chatted Users */}
                        <div className="last-chatted-users">
                            <h3>Last Chatted</h3>
                            {lastChatted.length > 0 ? (
                                lastChatted.map(({ userId, telegramUsername }) => {
                                    const userMessages = messagesByUser[userId] || [];
                                    const lastMessage = userMessages[userMessages.length - 1];
                                    const seen = lastMessage?.seen || 0;

                                    return (
                                        <div
                                            key={`last-${userId}`}
                                            className={`user-item ${selectedUser === userId ? 'selected' : ''} ${seen === 0 ? 'unseen' : ''}`}
                                            onClick={() => handleUserClick(userId)}
                                        >
                                            <p><strong>{telegramUsername || 'No Username'}</strong></p> {/* Handle undefined telegramUsername */}
                                            {lastMessage && (
                                                <>
                                                    <p><strong>{lastMessage.sender}:</strong> {truncateMessage(lastMessage.text, 20)}</p>
                                                    <p>
                                                        <strong>Timestamp:</strong>{' '}
                                                        {new Date(lastMessage.timestamp).toLocaleString(undefined, {
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                            second: '2-digit',
                                                        })}
                                                    </p>
                                                </>
                                            )}
                                        </div>
                                    );
                                })
                            ) : (
                                <p>No last chatted users.</p>
                            )}
                        </div>

                        {/* Chat Box */}
                        {selectedUser && (
                            <div className="message-box-container">
                                <div className="messages-container" ref={messagesEndRef}>
                                    {(messagesByUser[selectedUser] || []).map((message, index) => (
                                        <div
                                            key={`msg-${index}`}
                                            className={`message-container ${message.sender}`}
                                        >
                                            <div className={`message ${message.sender}`}>
                                                <p>{message.text}</p>
                                            </div>
                                            <div className={`timestamp ${message.sender}`}>
                                                <small>{new Date(message.timestamp).toLocaleString()}</small>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="response-section">
                                    <input
                                        value={response}
                                        onChange={(e) => setResponse(e.target.value)}
                                        placeholder="Type your response..."
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') sendResponse();
                                        }}
                                    />
                                    <button onClick={sendResponse}>Send</button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminChat;