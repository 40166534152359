import React from "react";
import ChatBubble from "../../components/ChatBubble";

const Testing = () => {
    return(
        <div className="testing-main">
            <ChatBubble/>
        </div>
    )
}

export default Testing;