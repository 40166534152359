import React, { useEffect, useState, useRef } from "react";
import { AiFillMessage } from "react-icons/ai";
import { PiPaperPlaneRightFill } from "react-icons/pi";
import { IoClose } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "./ChatBubble.css";
import notificationSound from "./notification1.mp3";

const getUserId = () => {
    const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
        const [key, value] = cookie.split("=");
        acc[key] = value;
        return acc;
    }, {});

    if (!cookies["userId"]) {
        const newUserId = uuidv4();
        document.cookie = `userId=${newUserId}; path=/; max-age=${60 * 60 * 24 * 365}`;
        return newUserId;
    }

    return cookies["userId"];
};

const ChatBubble = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [isMobile, setIsMobile] = useState(false);
    const [chatIcon, setChatIcon] = useState(0);
    const [closeIcon, setCloseIcon] = useState(0);
    const [sendIcon, setSendIcon] = useState(0);
    const audioRef = useRef(new Audio(notificationSound));
    const location = useLocation();
    const userId = getUserId();
    const isAdmin = location.pathname === "/admin";

    const [telegramUsername, setTelegramUsername] = useState("");
    const [email, setEmail] = useState("");
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    if (isAdmin) {
        return null;
    }

    const handleFormSubmit = () => {
        if (telegramUsername.trim() && email.trim()) {
            setIsFormSubmitted(true);
            localStorage.setItem('isFormSubmitted', 'true'); // Remember form submission
        } else {
            alert("Please fill in all fields.");
        }
    };

    useEffect(() => {
        const formSubmitted = localStorage.getItem('isFormSubmitted') === 'true';
        setIsFormSubmitted(formSubmitted);
    }, []);

    useEffect(() => {
        const fetchChatHistory = async () => {
            try {
                const response = await fetch(
                    `https://cryptoxnft.org/chat/user/messages/${userId}`
                );
                if (response.ok) {
                    const data = await response.json();
                    setMessages(data.messages);

                    if (data.messages.length > 0) {
                        setIsFormSubmitted(true);
                    }
                } else {
                    console.error("Failed to fetch chat history");
                }
            } catch (error) {
                console.error("Error fetching chat history:", error);
            }
        };

        fetchChatHistory();
    }, [userId]);

    useEffect(() => {
        const updateCurrentPage = async () => {
            try {
                await fetch("https://cryptoxnft.org/chat/update-current-page", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ userId, currentPage: location.pathname }),
                });
            } catch (error) {
                console.error("Failed to update current page:", error);
            }
        };

        updateCurrentPage();
    }, [userId, location.pathname]);

    useEffect(() => {
        const eventSource = new EventSource(
            `https://cryptoxnft.org/chat/events/${userId}`
        );

        eventSource.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.text) {
                setMessages((prev) => [...prev, message]);
                audioRef.current.play();
            }
        };

        eventSource.onerror = (err) => {
            console.error("SSE connection error:", err);
            eventSource.close();
        };

        return () => {
            eventSource.close();
        };
    }, [userId]);

    const handleSendMessage = async () => {
        if (!isFormSubmitted) {
            alert("Please submit your Telegram username and email first.");
            return;
        }
    
        if (message.trim()) {
            const newMsg = {
                text: message,
                sender: "user",
                id: uuidv4(),
                userId,
                telegramUsername, // Always include username and email
                email
            };
    
            setMessages((prev) => [...prev, newMsg]);
    
            try {
                await fetch("https://cryptoxnft.org/chat/new-message", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(newMsg),
                });
                setMessage("");
            } catch (error) {
                console.error("Failed to send message:", error);
            }
        }
    };

    useEffect(() => {
        const handleResize = () => {
            const windowWidth = window.innerWidth;
            const vw = windowWidth / 100;

            if (isMobile) {
                setChatIcon(10 * vw);
                setCloseIcon(7 * vw);
                setSendIcon(7 * vw);
            } else {
                setChatIcon(2.5 * vw);
                setCloseIcon(1.5 * vw);
                setSendIcon(1.5 * vw);
            }
        };

        const detectDeviceType = () => {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            setIsMobile(/android|iPad|iPhone|iPod/i.test(userAgent));
        };

        detectDeviceType();
        handleResize();

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [isMobile]);

    useEffect(() => {
        const chatBox = document.querySelector(".chat-messages");
        if (chatBox) {
            chatBox.scrollTop = chatBox.scrollHeight;
        }
    }, [messages]);

    const handleOpenChat = () => {
        setOpen(true);
    };

    return (
        <div className="chat-bubble-container">
            {!open && (
                <div className="chat-icon" onClick={handleOpenChat}>
                    <AiFillMessage size={chatIcon} />
                    <p className="chat-icon-text">CHAT WITH US</p>
                </div>
            )}
            {open && (
                <div className="chat-box">
                    <div className="chat-header">
                        <span>Chat with us!</span>
                        <button onClick={() => setOpen(false)}>
                            <IoClose size={closeIcon} />
                        </button>
                    </div>
                    {!isFormSubmitted ? (
                        <div className="chat-form">
                            <div className="chat-form-container">
                                <p>Please fill in to start chatting:</p>
                                <input
                                    type="text"
                                    value={telegramUsername}
                                    onChange={(e) => setTelegramUsername(e.target.value)}
                                    placeholder="Telegram Username"
                                />
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Email"
                                />
                                <button onClick={handleFormSubmit}>SUBMIT</button>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="chat-messages">
                                {messages.map((msg, index) => (
                                    <div
                                        key={msg.id || index}
                                        className={`chat-message ${
                                            msg.sender === "user"
                                                ? "user-message"
                                                : "support-message"
                                        }`}
                                    >
                                        {msg.text}
                                    </div>
                                ))}
                            </div>
                            <div className="chat-input">
                                <div className="chat-input-container">
                                    <input
                                        type="text"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        placeholder="Type a message..."
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleSendMessage();
                                            }
                                        }}
                                    />
                                    <div className="chat-input-icon" onClick={handleSendMessage}>
                                        <PiPaperPlaneRightFill size={sendIcon} />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default ChatBubble;
